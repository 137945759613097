.center-in-content {
  max-width: 100vw !important;

  .mat-mdc-dialog-container {
    max-width: none !important;
  }
}

.gbr-container {
  .data-point {
    display: -ms-grid;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    column-gap: 8px;
    margin-bottom: 12px;

    &.department {
      grid-column: auto / span 2;
    }

    .icon {
      grid-area: 1 / 1 / 2 / 2;

      .mat-icon {
        color:var(--darkgrey900);
        $size: 20px;
        font-size: $size;
        width: $size;
        height: $size;
      }
    }

    .title {
      align-self: center;
      font-family: 'Siemens Sans Black';
      grid-area: 1 / 2 / 2 / last-line;
      white-space: nowrap;
    }

    .value {
      grid-area: 2 / 2 / 3 / last-line;
      min-height: 18px;
    }
  }
}
