:root {
  // Purple – Primary
  --purple900: #401966;
  --purple900-rgb: 54, 19, 88;
  --purple800: #4D217A;
  --purple800-rgb: 65, 23, 107;
  --purple700: #632D9A;
  --purple700-rgb: 76, 27, 126;
  --purple600: #753BB0;
  --purple-contrast600: #8A01E5;
  --purple600-rgb: 94, 50, 139;
  --purple500: #8751BD;
  --purple500-rgb: 112, 73, 152;
  --purple400: #996BC7;
  --purple400-rgb: 130, 95, 165;
  --purple300: #AD87D4;
  --purple300-rgb: 148, 118, 178;
  --purple200: #C9ADE6;
  --purple200-rgb: 166, 141, 190;
  --purple100: #DECAF2;
  --purple100-rgb: 184, 164, 203;
  --purple50: #EBDEF7;
  --purple50-rgb: 201, 187, 216;

  // Petrol – Secondary
  --petrol900: #006666;
  --petrol800: #008585;
  --petrol700: #009999;
  --petrol600: #09AAAA;
  --petrol500: #2EB8B8;
  --petrol400: #62CBCB;
  --petrol300: #90DADA;
  --petrol200: #B3E5E5;
  --petrol100: #C6ECEC;
  --petrol50: #D9F2F2;
  --petrol-accent: #00F8F8;

  --x: #4FC0D8;
  // Dark Grey -> grey
  --darkgrey900: #1b1534;
  --darkgrey900-rgb: 27, 21, 52;
  --darkgrey800: #36314c;
  --darkgrey700: #514c66;
  --darkgrey600: #6b6680;
  --darkgrey600-rgb: 107, 102, 128;
  --darkgrey500: #878299;
  --darkgrey400: #a39fb0;
  --darkgrey300: #bdbcc8;
  --darkgrey300-rgb: 189, 188, 200;
  --darkgrey200: #CBCAD3;
  --darkgrey100: #D9D8DF;
  --darkgrey50: #E6E6EA;

  // Light Grey -> background
  --lightgrey600: #BAC4D4;
  --lightgrey500: #C4CDDB;
  --lightgrey400: #CFD5E1;
  --lightgrey300: #D9DEE8;
  --lightgrey200: #E3E7EF;
  --lightgrey100: #EEEFF5;
  --lightgrey50: #f8f8fc;

  // Extra Colors
  --blue600: #0066cc;
  --blue600-rgb: 0, 102, 204;
  --blue500: #408cd9;
  --blue500-rgb: 64, 140, 217;
  --blue400: #7eb3e8;
  --blue400-rgb: 126, 179, 232;
  --blue300: #7eb3e8;
  --blue200: #7eb3e8;
  --blue200-rgb: 126, 179, 232;
  --blue100: #7eb3e8;
  --blue50: #7eb3e8;

  // Orange -> yellow
  --orange600: #A65800;
  --orange600-rgb: 255, 140, 26;
  --orange500: #E08300;
  --orange500-rgb: 255, 170, 51;
  --orange400: #F5A300;
  --orange400-rgb: 255, 196, 112;
  --orange300: #FFB829;
  --orange200: #FFCA61;
  --orange100: #FFDA8F;
  --orange50: #FFE9BD;

  --red600: #650B13;
  --red600-rgb: 184, 20, 48;
  --red500: #850F23;
  --red500-rgb: 229, 26, 60;
  --red400: #AA132C;
  --red400-rgb: 237, 94, 118;
  --red300: #CF1736;
  --red200: #EB4763;
  --red100: #F28C9D;
  --red50: #FDD3DA;

  --green600: #205D14;
  --green600-rgb: 41, 163, 71;
  --green500: #2A7A1A;
  --green500-rgb: 47, 188, 82;
  --green400: #359C21;
  --green400-rgb: 105, 211, 119;
  --green300: #48B931;
  --green200: #74D161;
  --green100: #A6E599;
  --green50: #D7F5D1;

  --ion-box-shadow-default-color: rgba(48, 50, 50, 0.3);

  // Preset Heights
  --header-height: 65px;
  --sidebar-collapsed-width: 55px;
  --viewport-height: 100vh;

  // Header Colors
  --header-table-overlay-title: rgb(157, 187, 230);
  --header-table-overlay: rgb(190, 207, 237);
  --header-table-overlay-border: rgb(175, 198, 240);
  --header-table-overlay-content: rgb(190, 207, 237);

  --header-overlay-title: rgb(208, 218, 232);
  --header-overlay: rgb(222, 228, 239);
  --header-overlay-border: rgb(222, 228, 239);
  --header-overlay-border-contrast: rgb(204, 237, 236);

  --header-overlay-title-contrast: rgb(177, 227, 227);
  --header-overlay-contrast: rgb(204, 237, 236);
} 