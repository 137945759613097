.close-dialog-button-wrapper {
  display: flex;
  justify-content: flex-end;
  height: 100%;

  button {
    background-color: white !important;
    height: 100%;
    padding: 0px 1em 0px 0.5em;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    border-radius: 0 15px 0 0;

    mat-icon {
      user-select: none;
      color: var(--darkgrey900);
    }

    &:hover {
      background-color: var(--lightgrey400);
      border-color: var(--darkgrey400);
    }

    &:active {
      background-color: var(--darkgrey400);
      border-color: var(--darkgrey500);
    }
  }
}
