.mdc-tooltip {
  font-family: 'Siemens Sans';
  color: white;
  font-size: 10pt;
  border-radius: 6px !important;
  background-color: var(--darkgrey800);
  white-space: pre-wrap;

  .mdc-tooltip__surface {
    background-color: inherit;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    letter-spacing: inherit;
    border-radius: inherit;
    line-height: inherit;
    padding: 8px;
  }
}

.wide-tooltip {
  max-width: 400px !important;
}
