.coin-chip-info {
  display: flex;
  align-items: center;

  border-radius: 100px;
  border: 1px solid var(--lightgrey200);

  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;

  &.primary {
    border-color: var(--purple800);
  }

  &.big {
    p {
      font-size: 0.8rem;
    }

    .title,
    .value {
      padding: 4px 8px;
    }
  }

  p {
    font-family: 'Siemens Sans Black';
    font-size: 0.6375rem;
  }

  &.organization {
    margin-top: 8px;
  }

  .title,
  .value {
    padding: 2px 8px;
    white-space: nowrap;
  }

  .title {
    border-radius: 100px;
    background-color: var(--lightgrey200);
    display: flex;
    align-items: center;
  }

  $iconSize: 12px;
  .mat-icon {
    font-size: $iconSize;
    width: $iconSize;
    height: $iconSize + 2;
    vertical-align: middle;
  }

  .title .mat-icon {
    height: $iconSize;
    margin-right: 6px;
  }

  &.primary {
    border-color: var(--purple800);

    .title {
      background-color: var(--purple800);
    }
  }
}
