/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 */

@import 'simplebar/dist/simplebar.css';

/* Structured Packages */
@import './styles/_global/normalize';
@import './styles/_global/variables';
@import './styles/_global/materialicons';
@import './styles/_global/mixins';
@import './styles/_global/typography';
@import './styles/_global/base';
@import './styles/_global/matslider';
@import './styles/layouts/lay-wrapper';
@import './styles/atoms/a-text';
@import './styles/components/simple-table';
@import './styles//components/ripple.scss';
@import './styles//components/reassignments.scss';

/* To add more visual modifications use this SCSS below */
@import './styles/_global/additionalmods';
@import './styles/_global/cms';

/* Add Global Organizational Stylings here */
@import './styles/_organizational-packages/siemens-energy';
@import 'input-base';

/* Lib Styles */
@import 'ngx-toastr/toastr';
@import '@ng-select/ng-select/themes/default.theme.css';
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.bubble.css';
@import 'quill/dist/quill.snow.css';

/* Handsontable default styles */
@import 'coin-handsontable';
