.app-ripple {
  position: absolute;
  border-radius: 50%;
  background-color: white;
  opacity: 0.2;
  transform: scale(0);
  transition: 800ms ease-out;
  z-index: -1;
  left: 0;
  right: 0;
  margin: auto;

  &.spread {
    transform: scale(2);
    opacity: 0;
  }
}
