.toast-container {
  * {
    font-family: 'Siemens Sans';
  }

  .ngx-toastr {
    background-size: 18px;
    background-position-x: 17px;
    border-radius: 6px;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 83%;
      background-color: var(--darkgrey700);
      content: '';
    }

    > div {
      position: relative;
      margin-left: 16px;
    }
  }

  .toast-info {
    background-color: var(--lightgrey600);
  }
  .toast-success {
    background-color: var(--petrol500);
  }
  .toast-warning {
    background-color: var(--orange400);
  }
  .toast-error {
    background-color: var(--red300);
  }
}
