@import 'button-colors';

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}

@mixin buttonColor($colorMap, $type, $state: 'default') {
  $background: map-deep-get($colorMap, $type, $state, 'background');
  $border: map-deep-get($colorMap, $type, $state, 'border');
  $color: map-deep-get($colorMap, $type, $state, 'color');
  $color: black !default;

  @if $background {
    background-color: $background;
  } @else {
    background-color: none;
  }
  @if $border {
    border: 2px solid $border;
  } @else {
    border: none;
  }

  color: $color;

  .mat-icon {
    color: $color;
  }
}

@mixin buttonTypeColors($colorMap, $type) {
  color: var(--darkgrey900);
  @include buttonColor($colorMap, $type);

  &:hover {
    @include buttonColor($colorMap, $type, 'hover');
  }
  &:focus {
    @include buttonColor($colorMap, $type, 'focus');
  }
  &:active {
    @include buttonColor($colorMap, $type, 'press');
  }
  &:disabled {
    @include buttonColor($colorMap, $type, 'disabled');
  }
}

button.no-style {
  padding: 0px;
  margin: 0px;
  background-color: transparent;

  &.with-highlights {
    &:hover,
    &:focus {
      background: rgba(black, 0.25);
    }
  }
}

button.icon-button,
.icon-button button {
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  user-select: none;
  width: 38px;
  height: 38px;

  .mat-icon {
    color: var(--purple700);
  }
}

$types: 'primary', 'secondary', 'white', 'text', 'white-text';

button:not(.no-style, [role=switch]) {
  @include buttonTypeColors($button-colors, 'default');
}

@each $type in $types {
  button.#{$type},
  .#{$type} button {
    @include buttonTypeColors($button-colors, $type);
  }
}
