@import 'handsontable/dist/handsontable.full.min.css'; // Handsontable default styles
@import '_global/typography';
@import 'scaling';

div.htContextMenu table.htCore {
  width: auto; // workaround for https://github.com/handsontable/handsontable/issues/10958
}
.ht_master .wtHolder {
  overscroll-behavior: contain;
}

@mixin violation($color) {
  padding-left: base(3.75);

  &::before {
    content: 'error_outline';
    font-family: 'Material Icons';
    font-size: base(2.25);
    color: $color;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: base(1);
  }

  &:first-child {
    padding-left: base(4.75);

    &::before {
      padding-left: base(2);
    }
  }
}

@mixin coin-hot-table {
  th,
  td {
    border-color: var(--lightgrey400);
  }
  td {
    @include copy-txt(black);
    padding: 0 base(1);
    height: base(5);
    overflow: visible;
    position: relative;

    &.changed {
      background-color: var(--petrol50) !important;
    }
    &.terminated {
      background-color: var(--red50) !important;
    }
    &.error {
      @include violation(var(--red300));
    }
    &.warning {
      @include violation(var(--orange400));
    }
    &:first-child {
      padding-left: base(2);
    }

    &.area {
      &::before {
        // Set background-color with rgb opacity and keep opacity 1, so that other before content is not affected
        background-color: rgb(0, 94, 255, 0.1);
        opacity: 1;
      }
    }

    &:not(.area):hover::after {
      z-index: 20;
      top: base(-3);
      position: absolute;
      content: var(--hot-tooltip, undefined);
      background-color: #616161;
      color: white;
      text-align: center;
      left: 50%;
      pointer-events: none;
      width: max-content;
      box-sizing: border-box;
      font-size: base(1.3);
      padding: base(0.6) base(1);
      border-radius: base(0.5);
      max-width: base(27);
    }

    input.htCheckboxRendererInput {
      $size: 18px;
      height: $size;
      width: $size;
      accent-color: var(--petrol800);
      &:not([disabled]) {
        cursor: pointer;
      }
    }
  }
  th {
    background: var(--lightgrey100);
    &.ht__highlight {
      background: var(--lightgrey300);
    }
  }

  thead th {
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.3);
    padding: base(1);
    text-align: left;
    box-sizing: border-box;

    &:first-child {
      padding-left: base(2);
    }

    button.changeType {
      border: none;
      background: none;

      &::before {
        font-family: 'Material Icons';
        content: 'keyboard_arrow_down';
        font-size: base(3);
        color: var(--darkgrey600);
      }
    }

    .relative {
      padding: 0;
    }
  }

  tbody {
    tr {
      td {
        background-color: white;
      }
      &[aria-rowindex$='1'],
      &[aria-rowindex$='3'],
      &[aria-rowindex$='5'],
      &[aria-rowindex$='7'],
      &[aria-rowindex$='9'] {
        td {
          background-color: var(--lightgrey50);
        }
      }
    }
  }
}

@mixin coin-table-button {
  border-radius: 50%;
  width: base(3);
  height: base(3);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--lightgrey200);
  margin: base(1);

  .material-icons {
    font-size: base(2);
    width: base(2);
    height: base(2);
  }
}
