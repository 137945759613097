@import "coin-theme";
@import './siemens-energy';
@import './close-dialog-button';
@import './buttons/buttons';
@import './toast.scss';
@import './tooltip.scss';
@import './icon.scss';
@import './mixins';
@import './coin-typography.scss';
@import './modules/gbr.scss';
@import './components/chip-info.scss';
@import './components/ripple.scss';
@import './components/coin-stepper.scss';
@import './components/mat-chip.scss';
@import './components/mat-dialog.scss';
@import './components/mat-input.scss';
@import './components/mat-select.scss';
@import './components/mat-radio-button.scss';
@import './components/mat-slide-toggle.scss';
@import './components/mat-menu.scss';
@import './components/mat-checkbox.scss';
@import './components/mat-card.scss';
@import './components/update-notifier.scss';
@import './components/coin-dialog-frame';



* {
  user-select: text;
}
// editor fix for the above rule
quill-editor * {
  user-select: auto;
}

.hover {
  transition: transform 200ms;

  &:hover {
    transform: scale(0.9);
  }
}

[hidden] {
  display: none !important;
}
