// Adjusted Angular-Component styles to match visuals for Version <15.

.mat-mdc-card {
  background: white;

  .mat-mdc-card-content,
  .mat-mdc-card-header {
    padding: 0;
    &:last-child {
      padding: 0;
    }
  }

  .mat-mdc-card-header-text {
    margin: 0 16px;
  }

  &:not([class*='mat-elevation-z']) {
    box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
  }
}
