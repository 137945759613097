.coin-stepper-container .mat-stepper-horizontal {
  background-color: unset;
  position: relative;
  height: 100%;

  .mat-horizontal-stepper-header-container {
    z-index: 100;
    position: relative;
    background-color: var(--lightgrey100);
  }

  .mat-horizontal-content-container {
    position: absolute;
    top: 0px;
    z-index: 10;
    height: 100%;
    width: 100%;
    padding: 0px;

    .mat-horizontal-stepper-content[aria-expanded='true'] {
      height: 100%;
    }
  }

  .mat-step-header {
    flex-direction: column;
    justify-content: center;

    *:not(.mat-icon) {
      font-family: 'Siemens Sans';
    }

    .mat-step-label {
      margin-top: 5px;
    }

    .mat-step-icon {
      background-color: white;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px;
      transition: all 200ms linear;

      &.mat-step-icon-selected {
        background-color: var(--purple700);

        span {
          color: white;
        }
      }

      &.mat-step-icon-state-edit {
        background-color: var(--petrol500);

        mat-icon {
          color: white;
        }
      }

      * {
        color: var(--darkgrey900);
      }

      span {
        font-size: 1em;
        font-family: 'Siemens Sans Black';
      }
    }
  }

  .mat-stepper-horizontal-line {
    border-top-width: 2px;
    margin-bottom: 14px;
  }
}
