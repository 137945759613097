// Adjusted Angular-Component styles to match visuals for Version <15.

div.mdc-menu-surface.mat-mdc-select-panel {
  flex-shrink: 0;
  width: auto;
  padding: 0;
  min-width: 100%;

  .mdc-list-item__primary-text {
    width: 100%;
  }
}