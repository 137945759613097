@use '@angular/material' as mat;
@include mat.core();

$coin-primary: mat.m2-define-palette(
  (
    50: var(--purple50),
    100: var(--purple100),
    200: var(--purple200),
    300: var(--purple300),
    400: var(--purple400),
    500: var(--purple500),
    600: var(--purple600),
    700: var(--purple700),
    800: var(--purple800),
    900: var(--purple900),
    contrast: (
      50: black,
      100: black,
      200: black,
      300: black,
      400: white,
      500: white,
      600: white,
      700: white,
      800: white,
      900: white
    )
  ),
  600
);

$coin-secondary: mat.m2-define-palette(
  (
    50: var(--petrol50),
    100: var(--petrol100),
    200: var(--petrol200),
    300: var(--petrol300),
    400: var(--petrol400),
    500: var(--petrol500),
    600: var(--petrol600),
    700: var(--petrol700),
    800: var(--petrol800),
    900: var(--petrol900),
    contrast: (
      50: black,
      100: black,
      200: black,
      300: black,
      400: white,
      500: white,
      600: white,
      700: white,
      800: white,
      900: white
    )
  ),
  600
);

// For reference: https://material.angular.io/guide/typography
$coin-typography: mat.m2-define-typography-config(
  $headline-5: mat.m2-define-typography-level(2.275rem, 2.2rem, 400, ('Siemens Sans Black', Tahoma, Geneva, sans-serif), 0.008rem),
  $headline-6: mat.m2-define-typography-level(1.275rem, 1.15rem, 400, ('Siemens Slab Bold', Tahoma, Geneva, sans-serif), 0.01rem),
  $subtitle-1: mat.m2-define-typography-level(1.15rem, 1.1rem, 400, ('Siemens Sans Black', Tahoma, Geneva, sans-serif), 0.01rem),
  $subtitle-2: mat.m2-define-typography-level(1rem, 1.05rem, 400, ('Siemens Sans Black', Tahoma, Geneva, sans-serif), 0.01rem),
  $body-1: mat.m2-define-typography-level(0.75rem, 1.05rem, 400, ('Siemens Sans', Tahoma, Geneva, sans-serif), 0.02rem),
  $body-2: mat.m2-define-typography-level(0.7rem, 0.875rem, 400, ('Siemens Sans', Tahoma, Geneva, sans-serif), 0.02rem),
  $caption: mat.m2-define-typography-level(0.65rem, 1rem, 400, ('Siemens Sans Bold', Tahoma, Geneva, sans-serif), 0.02rem),
  $button: mat.m2-define-typography-level(0.75rem, 1.05rem, 400, ('Siemens Sans Black', Tahoma, Geneva, sans-serif), 0.02rem)
);

$coin-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $coin-primary,
      accent: $coin-secondary
    ),
    typography: $coin-typography
  )
);

@include mat.all-component-themes($coin-theme);
@include mat.all-component-typographies($coin-theme);

@for $elevation from 0 to 24 {
  .mat-elevation-z#{$elevation} {
    @include mat.elevation($elevation);
  }
}
