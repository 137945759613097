// Adjusted Angular-Component styles to match visuals for Version <15.

.mat-mdc-menu-content {
  .mat-mdc-menu-item {
    &[disabled] {
      opacity: 1;
    }

    .mdc-list-item__primary-text {
      font-family: Roboto, Helvetica Neue, sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: inherit;
    }
  }
}
