@import 'libs/shared/util-styles/src/mixins';

.lay-wrapper {
  width: 100%;
  position: relative;
  transition: transform 0.5s ease-in-out;

  > * {
    &:last-child {
      @include mq(print) {
        border-bottom: none !important;
      }
    }
  }

  /**** Layout Wrapper - Main Types ****/

  &--header {
    top: 0;
    min-width: 768px;
    background: white;
    position: fixed;
    z-index: 12;

    @include mq(print) {
      display: none;
    }
  }

  &--footer {
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 60px;
    opacity: 0;
    position: absolute;

    @include mq(print) {
      display: none;
    }

    &.dark {
      background: var(--darkgrey700);
    }

    &.lightfont {
      span,
      a {
        color: white !important;
      }
    }

    &.show {
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }
  }

  /**** Layout Wrapper - Grid Type ****/

  &--default,
  &--grid-width {
    width: 92.5%;
    margin: 0 auto;
    transition: max-width 0.25s ease-in-out;
    position: relative;
  }

  &--full-height {
    min-height: 100vh;
  }

  /** Layout Wrapper - Background Colors **/

  &--light {
    background: white;
  }

  &--grey-light {
    background: var(--lightgrey100);
  }

  &--grey-dark {
    background: var(--darkgrey700);
  }

  &--top-distance {
    margin-top: 130px;

    @include mq(print) {
      margin-top: 0;
    }

    &-small {
      margin-top: var(--header-height);

      @include mq(print) {
        margin-top: 0;
      }
    }
  }

  &-medium {
    padding-top: 90px;

    @include mq(print) {
      padding-top: 0;
    }
  }

  &--bottom-distance {
    margin-bottom: 100px;

    &-medium {
      margin-bottom: 75px;
    }

    &-small {
      margin-bottom: 50px;
    }
  }

  // headroom styles
  &.headroom {
    transition: top 200ms linear;
  }

  &.headroom--pinned {
    top: 0;
  }

  &.headroom--unpinned {
    top: -var(--header-height);
  }
}
