.accept-create-publish-dialog {
    .mat-step-icon {
        background-color: var(--purple800);
    }

    .mat-step-icon-content {
        span {
            font-size: 1rem;
        }
    }
}