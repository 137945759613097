.mat-mdc-radio-button {
  .mdc-radio__background::before,
  .mat-ripple {
    display: none;
  }

  .mdc-radio {
    padding: 0 !important;
  }
}
