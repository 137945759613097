@import 'scaling';

.dialog-frame-with-tabs {
  .header {
    display: flex;
    flex-grow: 1;
    height: base(6);
    align-items: center;


    .title-container {
      display: flex;
      align-items: center;
      gap: base(2);
      h3 {
        color: white;
      }
    }

    .dialog-title {
      margin: base(1.5) base(4);
      display: flex;
      flex: 1;
      gap: base(2);
      align-items: center;
      > span {
        font: inherit;
      }
    }

    coin-v2-tabs {
      flex: 3;
      height: 100%;
      margin: 0 auto;
    }
  }
}
