// Ported Angular-Component styles for update to Version 15.

.mdc-dialog__container {
  box-shadow: none;
  border-radius: 16px;
  --mat-dialog-transition-duration: 0ms;

  &:not(:has(app-loading-screen)) {
    --mat-dialog-transition-duration: 150ms;
    background: white;

    &:has(coin-v2-dialog-frame) {
      background: none;
      .mdc-dialog__surface {
        overflow: visible;
      }
    }
  }
}

.mat-mdc-dialog-surface.mdc-dialog__surface {
  background: none !important;
  border-radius: 16px !important;
  position: static;
}

mat-dialog-container mat-tab-body {
  background-color: white;
}
