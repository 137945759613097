$border: 1px solid var(--darkgrey300);

.cms-simple-table {
  zoom: 0.8;

  &.landscape-user-block {
    tbody tr > td {
      &:nth-child(-n + 2) {
        width: 25%;
      }

      &:nth-child(n + 3) {
        width: calc(50% / 3);
      }
    }
  }

  &.review-period-data {
    tbody tr > td {
      &:nth-child(-n + 4) {
        width: 12.5%;
      }

      &:nth-child(n + 5) {
        width: calc(50% / 3);
      }
    }
  }

  tbody {
    tr {
      background: none !important;
      border: none !important;

      > td {
        font-weight: bold !important;
        text-align: left !important;
        border: none !important;
        padding: 3px !important;
      }

      &:first-child {
        > td {
          font-weight: normal !important;
        }
      }
    }
  }
}

.merit-letter-table-element {
  width: 100%;
  border-collapse: separate;

  .header,
  tr:first-child {
    background: var(--lightgrey200) !important;

    th:first-child {
      border-right: 1px solid var(--lightgrey200);
    }
  }

  .blue {
    background-color: var(--petrol100) !important;
  }

  tr {
    position: relative;

    > :first-child {
      width: 20px;
    }

    > *:nth-child(n + 3) {
      width: 15%;
    }

    &:nth-child(odd) {
      background-color: var(--lightgrey50);
    }

    &:first-child {
      th,
      td {
        white-space: nowrap;
        text-align: left;
        text-align: right;
        border-top: $border;

        &:nth-child(3) {
          font-weight: bold;
        }
      }
    }

    &:not(:first-child) {
      td:first-child {
        font-weight: bold;
      }

      td {
        &:first-child {
          text-align: center;
        }

        &:nth-child(2) {
          p:first-child {
            font-weight: bold;
          }
        }

        &:not(:nth-child(2)) {
          white-space: nowrap;
        }

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          text-align: right;
        }
      }
    }

    &:last-child {
      td {
        zoom: 1.05;
      }
    }

    th,
    td {
      font-family: 'Siemens Sans', Tahoma, Geneva, sans-serif;
      letter-spacing: 0.02rem;
      font-weight: 400;
      padding: 5px;
      margin: 2px;

      border-right: $border;
      border-bottom: $border;
      &:first-child {
        border-left: $border;
      }
    }
  }
}

.preview-cms-watermark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  zoom: 8;
  opacity: 0.4;
}
