// Adjusted Angular-Component styles to match visuals for Version <15.

mat-pseudo-checkbox:after {
  color: var(--lightgrey50) !important;
}

mat-option:not(.mat-mdc-option-multiple) > mat-pseudo-checkbox {
  display: none;
}

.mdc-checkbox__ripple {
  display: none;
}

:root {
  --mdc-checkbox-state-layer-size: 0 !important;
}
