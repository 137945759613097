$scrollbar-width: 10px;
$border-radius: 30px;

// Scroll Bar Mixins
@mixin primary-scrollbar-vertical() {
  .simplebar-track.simplebar-horizontal {
    display: none;
  }

  .simplebar-content {
    margin-right: 30px;
    overflow: hidden;
    padding: calc(20px / 2) 0 !important;
  }

  .simplebar-track.simplebar-vertical {
    width: $scrollbar-width;
    border-radius: $border-radius;
    background: var(--lightgrey400) !important;
    margin: calc(30px / 2) 0 !important;
  }

  .simplebar-scrollbar:before {
    border-radius: $border-radius;
    width: $scrollbar-width;
    background: var(--darkgrey500);
    opacity: 1;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
  }

  &.simple-table {
    .simplebar-track.simplebar-vertical {
      background: var(--lightgrey300) !important;
    }
  }

  &.modal-appearance {
    .simplebar-content {
      padding: 0 !important;
      margin: calc(20px / 2) 0 !important;
    }

    .simplebar-track.simplebar-vertical {
      background: rgb(148, 153, 153);
      margin: calc(30px / 2) 0 !important;
    }

    .simplebar-scrollbar:before {
      background: rgb(29, 109, 133);
    }
  }

  &.sidebar-appearance {
    .simplebar-track.simplebar-vertical {
      background: rgb(109, 205, 204);
    }

    .simplebar-scrollbar:before {
      background: rgb(0, 147, 168);
    }
  }

  &.no-space {
    .simplebar-content {
      padding: 0 !important;
      margin: 0 !important;
    }

    .simplebar-track.simplebar-vertical {
      margin: 0 !important;
    }
  }

  &.scroll-space {
    .simplebar-track.simplebar-vertical {
      right: -25px;
    }

    .simplebar-content {
      padding: 0 !important;
      margin: 0 !important;
    }

    .simplebar-track.simplebar-vertical {
      margin: 0 !important;
    }
  }
}

@mixin contrast-scrollbar-vertical() {
  @include primary-scrollbar-vertical();

  .simplebar-track.simplebar-vertical {
    background: var(--petrol900);
  }

  .simplebar-scrollbar:before {
    background: white;
  }
}

@mixin primary-scrollbar-horizontal() {
  .simplebar-track.simplebar-vertical {
    display: none;
  }

  .simplebar-track.simplebar-horizontal {
    height: 5px;
    border-radius: $border-radius;
    background: var(--lightgrey50);
    opacity: 0.7;
    margin-right: 20px;
    z-index: 100;
  }

  .simplebar-scrollbar:before {
    border-radius: $border-radius;
    background: var(--petrol400);
    opacity: 0.8;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
  }

  .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    height: 5px !important;
    top: 0 !important;
  }
}

@mixin cdk-drag-simple-table() {
  .cdk-drag-preview {
    display: grid;
    gap: 0px;

    border-top: 2px solid var(--lightgrey200);

    &.no-border {
      border-top: none;
    }

    .content-column {
      box-sizing: border-box;
      background-color: white;
      display: flex;
      align-items: center;
      padding: 3px 5px;

      &.more-spacing {
        padding: 8px 10px;
      }

      &:nth-child(2n) {
        background-color: var(--lightgrey100);
      }

      &.force-border {
        border-left: 1px solid var(--lightgrey200);
      }

      &:last-child {
        padding-right: 20px !important; // width of the ngx-simplebar scrollbar
      }

      &.small-padding {
        padding: 5px 5px;
        padding-right: 5px !important;
      }

      p,
      input,
      textarea {
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      .edit-button {
        font-size: 18px;
        padding: 4px;
        border-radius: 50%;
        width: 24px;
        background-color: var(--purple700);
        color: white;
        height: 24px;
        display: inline-block;
        line-height: 17px;
        transition: transform 200ms;
        cursor: pointer;
        user-select: none;

        &:hover {
          transform: scale(0.9);
        }

        &--delete {
          background-color: var(--red400);
          padding: 4px 2px 4px 3px;
        }
      }

      input,
      textarea {
        font-size: 0.75rem;
        margin-right: 5px;
        border: 0;
        display: inline-block;
        color: black;
        max-height: 60px;
        border-bottom: 1px solid var(--darkgrey500);

        &:focus {
          border-bottom: 2px solid var(--purple700);
        }

        &:disabled {
          color: black;
          background: transparent;
        }
      }

      textarea {
        resize: none;
      }

      input {
        width: 90%;
        height: 28px;
        text-align: center;
      }

      i {
        color: var(--purple700);
      }
    }
  }
}
// mixin clearfix
@mixin clearfix() {
  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
  }
}

// mixin flex center
@mixin flex-center() {
  display: flex;
  justify-content: center;
  align-items: center;
}
